import { PlannerTask } from '@microsoft/microsoft-graph-types';
import { TextService } from 'services/TextService';
import { SharepointUserResolver } from 'services/SharepointUserResolver';
import { UserInfoService } from 'services/UserInfoService';
import { IFieldValueUser, IVistoListItemWithAssignee, IVistoListItemWithProgress, IVistoPlan, VistoActionItem, VistoKind } from 'sp';
import { PnpService } from 'services/PnpService';
import { INotify, NotificationType, notifyInfoBar } from 'services/Notify';
import strings from 'VistoWebPartStrings';

export class PlannerDataService {

  public static getTaskPercentComplete(task: PlannerTask): number {
    if (task.percentComplete > 99.99) {
      return 100;
    }
    if (task.checklistItemCount) {
      return Math.round(100 * (task.checklistItemCount - task.activeChecklistItemCount) / task.checklistItemCount);
    }
    if (task.percentComplete < 0.01) {
      return (task.startDateTime && task.dueDateTime) ? 0 : undefined;
    }
    return Math.round(task.percentComplete);
  }

  public static getTaskAssignedTo(task: PlannerTask): IFieldValueUser[] {
    return task.assignments ? Object.keys(task.assignments).map(k => ({ guid: k })) : [];
  }

  public static unparseTaskDate(src: Date): string {
    if (src) {
      let val = new Date(src.toDateString());
      // https://stackoverflow.com/questions/69711637/microsoft-planner-startdate-duedate-timezone
      // Planner tasks seem to start and finish at 10:00 UTC
      val.setUTCMinutes(10*60 - val.getTimezoneOffset()); 
      const result = val.toISOString();
      return result;
    } else {
      return null;
    }
  }

  public static parseTaskDate(src: string): Date {
    if (src) {
      let val = new Date(src);
      val.setHours(0);
      return val;
    } else {
      return null;
    }
  }

  public static parsePlannerTaskProgress(task: PlannerTask): IVistoListItemWithProgress {
    // Looks like a planner bug. See https://stackoverflow.com/questions/77316021
    const description = task.details?.description?.trim().split(/[\r\n]+/g).map(x => x.trim()).join('\n');
    return {
      kind: undefined,
      startDate: PlannerDataService.parseTaskDate(task.startDateTime),
      endDate: PlannerDataService.parseTaskDate(task.dueDateTime),
      percentComplete: PlannerDataService.getTaskPercentComplete(task),
      name: task.title,
      description: TextService.linesToHtml(description, 'p'),
      assignedTo: this.getTaskAssignedTo(task)
    };
  }

  public static async resolveUsers(plan: IVistoPlan, items: IVistoListItemWithAssignee[], notify: INotify) {

    for (const item of items) {
      const vals = item.assignedTo;
      if (Array.isArray(vals)) {
        for (const val of vals) {
          const key = val.guid || val.userName;
          try {
            const user = await UserInfoService.getUserInfo(key);
            if (user) {
              val.guid = user.id;
              val.userName = SharepointUserResolver.getUserName(user.userPrincipalName);
              val.title = TextService.getUserDisplayName(user.displayName);
            }
          } catch (error) {
            if (!val.userName) {
              val.userName = key;
              val.title = TextService.format(strings.Error_InvalidUserName);
            }
            const errorTitle = TextService.format(strings.Error_ResolveUsers_Title, { 
              key,
              itemTitle: item.kind ? TextService.formatTitle(item, plan.items) : TextService.format(strings.Error_ResolveUsers_PlannerTask)
            });
            PnpService.getErrorMessage(errorTitle, error).then(message => {
              notifyInfoBar(notify, {
                type: NotificationType.warn,
                group: 'Planner_Sync',
                message: errorTitle,
                error: message,
                kind: item.kind,
                guid: item.kind === VistoKind.Action ? (item as VistoActionItem).dpGuid : item.guid,
              });
            });
          }
        }
      }
    }

    await SharepointUserResolver.resolveUserIds(plan, items);
  }

  public static async getPlanProgress(sourceUrls: string[], statusDate: Date): Promise<{ [key: string]: IVistoListItemWithProgress }> {

    const results = {};
    for (const sourceUrl of sourceUrls) {
      results[sourceUrl] = {};
    }

    return results;
  }

  public static async getBucketProgress(sourceUrls: string[], statusDate: Date): Promise<{ [key: string]: IVistoListItemWithProgress }> {

    const results = {};
    for (const sourceUrl of sourceUrls) {
      results[sourceUrl] = {};
    }

    return results;
  }

}
