import { IVistoListItemWithProgress, IVistoPlan } from 'sp';

export class PlannerLinkService {

  public static isPlannerPlanLink(url: string) {
    return !!PlannerLinkService.parsePlannerPlanLink(url);
  }

  public static makePlannerPlanLink(tid: string, planId: string) {
    return `https://tasks.office.com/${tid}/Home/PlanViews/${planId}`;
  }

  public static parsePlannerPlanLink(link: string) {
    const re = new RegExp(PlannerLinkService.makePlannerPlanLink('(.*)', '(.*)'));
    const result = re.exec(link);
    return result && !PlannerLinkService.isPlannerBucketLink(link) && { tid: result[1], planId: result[2] };
  }

  public static isPlannerBucketLink(url: string) {
    return !!PlannerLinkService.parsePlannerBucketLink(url);
  }

  public static makePlannerBucketLink(tid: string, planId: string, bucketId: string) {
    return `https://tasks.office.com/${tid}/Home/PlanViews/${planId}#bucketId=${bucketId}`;
  }

  public static parsePlannerBucketLink(link: string) {
    const re = new RegExp(PlannerLinkService.makePlannerBucketLink('(.*)', '(.*)', '(.*)'));
    const result = re.exec(link);
    return result && { tid: result[1], planId: result[2], bucketId: result[3] };
  }

  public static isPlannerTaskLink(url: string) {
    return !!PlannerLinkService.parsePlannerTaskLink(url);
  }

  public static makePlannerTaskLink(tid: string, taskId: string) {
    return `https://tasks.office.com/${tid}/Home/Task/${taskId}`;
  }

  public static parsePlannerTaskLink(link: string) {
    const reTeams = /^https:\/\/teams\.microsoft\.com.*tenantId=([\w-]+).*task%2f([\w-]+)/i;
    const resultTeams = reTeams.exec(link);
    if (resultTeams) {
      return { tid: resultTeams[1], taskId: resultTeams[2] };
    }
    const reWeb = new RegExp(`https://tasks.office.com/(.*)/Home/Task/([^?]*)`, 'i')
    const resultWeb = reWeb.exec(link);
    return resultWeb && { tid: resultWeb[1], taskId: resultWeb[2] };
  }

  public static isPlannerLink(url: string) {
    return url && (PlannerLinkService.isPlannerPlanLink(url) || PlannerLinkService.isPlannerBucketLink(url) || PlannerLinkService.isPlannerTaskLink(url));
  }

  public static getLinkedPlannerKeys(p: IVistoPlan): string[] {
    return Object.keys(p.items).filter(key => {
      const item: IVistoListItemWithProgress = p.items[key];
      return PlannerLinkService.isPlannerLink(item.sourceItemUrl);
    });
  }
}
