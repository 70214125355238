import { IVistoPlan, IVistoPlanSettings, VistoKind } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { parseJSON } from 'shared/parse';
import { TextService } from './TextService';
import { ChangesService } from './ChangesService';
import { StorageService } from './StorageService';
import { ProgressService } from './ProgressService';
import { INotify } from './Notify';
import strings from 'VistoWebPartStrings';
import { ICommand } from './ICommand';
import { CommandName } from 'shared/CommandName';
import { LicenseService } from './LicenseService';

export class PlanSettingsService {

  private static defaultSettings: IVistoPlanSettings = {
    showIndicators: true,
    progressThresholdRed: -15,
    progressThresholdGreen: -3
  };

  public static getPlanSettings(plan: IVistoPlan): IVistoPlanSettings {
    try {
      return parseJSON(plan?.settingsJson) ?? PlanSettingsService.defaultSettings;
    } catch (err) {
      trackClient.error('Failed to load plan settings', err);
      return PlanSettingsService.defaultSettings;
    }
  }

  public static getStatusDate(settings: IVistoPlanSettings) {
    return (settings.useFixedDate && settings.fixedDate) ? settings.fixedDate : TextService.getDate(new Date());
  }

  public static setPlanSettings(plan: IVistoPlan, settings: IVistoPlanSettings): IVistoPlan {
    const settingsJson = JSON.stringify(settings);
    return (plan.settingsJson === settingsJson) ? plan : { ...plan, settingsJson, revision: plan.revision + 1 };
  }

  public static makeSetSettingCommand(plan: IVistoPlan, val: Partial<IVistoPlanSettings>, notify: INotify): ICommand {
    const oldPlanSettings = PlanSettingsService.getPlanSettings(plan);
    const newPlanSettings = { ...oldPlanSettings, ...val };
    const oldStatusDate = PlanSettingsService.getStatusDate(oldPlanSettings);
    const newStatusDate = PlanSettingsService.getStatusDate(newPlanSettings);
    const settingsJson = JSON.stringify(newPlanSettings);
    const changes = ChangesService.getChanges(plan, { settingsJson });
    return {
      prepare: async () => {
        return {
          do: async (p: IVistoPlan) => {
            if (changes.detected) {
              p = await StorageService.get(p.siteUrl).updatePlanItem(p, changes.newValues, notify);
            }
            if (newStatusDate !== oldStatusDate) {
              p = await ProgressService.ensurePercentComplete(p, notify);
            }
            p.statusDate = newStatusDate;
            
            return p;
          },
          undo: async (p: IVistoPlan) => {
            if (changes.detected) {
              p = await StorageService.get(p.siteUrl).updatePlanItem(p, changes.oldValues, notify);
            }
            if (newStatusDate !== oldStatusDate) {
              p = await ProgressService.ensurePercentComplete(p, notify);
            }
            p.statusDate = oldStatusDate;
            return p;
          },
        };
      },
      message: TextService.format(strings.PlanSettingsCommand_Message),
      name: CommandName.SetPlanSettings
    }
  }

}
