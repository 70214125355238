import React from 'react'
import { IButtonStyles, PrimaryButton, TooltipHost } from '@fluentui/react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const TopEditButton = (props: {
  disabled?: boolean,
  tooltipMessage?: string,
  readOnly: boolean,
  toggleReadOnly: () => void;
}) => {

  const editButtonStyles: IButtonStyles = {
    root: {
      padding: '0 8px',
      minWidth: 40,
    }
  };

  const buttonText = props.readOnly
    ? TextService.format(strings.TopToggleButton_Edit)
    : TextService.format(strings.TopToggleButton_View);

  return (
    <TooltipHost content={props.tooltipMessage}>
      <PrimaryButton id='ToggleEditButton' styles={editButtonStyles} disabled={props.disabled} onClick={props.toggleReadOnly}>{buttonText}</PrimaryButton>
    </TooltipHost>
    
  )
}
