export const AssistantInstructions = `
You are making a plan, splitting it into capabilities (like marketing, production, sales), 
and ambitions based on the user input. Create from 1 to 4 capabilitiy lines, each containing from 1 to 5 ambitions, depending on user input.
Preserve the model in the next iterations as much as possible.
`.trim();

export const AssitantPlanSchema =
{
  "name": "plan",
  "strict": true,
  "schema": {
    "type": "object",
    "properties": {
      "planName": {
        "type": "string",
        "description": "The name of the plan."
      },
      "planDescription": {
        "type": "string",
        "description": "The detailed description of the plan."
      },
      "positionName": {
        "type": "string",
        "description": "A final state (outcome) when the plan succeeds."
      },
      "positionDescription": {
        "type": "string",
        "description": "A detailed description of the final state (outcome) when the plan succeeds. Simple HTML or plain text."
      },
      "focusName": {
        "type": "string",
        "description": "to represents short-term goals or milestones. Should according to the context, reflecting milestones, sprints, or key phases of the plan. Default to just 'Focus' if not clear."
      },
      "focusDescription": {
        "type": "string",
        "description": "The detailed description of the focus. Simple HTML or plain text."
      },
      "capabilities": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string",
              "description": "The name of the capability line."
            },
            "description": {
              "type": "string",
              "description": "The detailed description of the capability line. Simple HTML or plain text."
            },
            "ambitions": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "name": {
                    "type": "string",
                    "description": "The name of the ambition."
                  },
                  "description": {
                    "type": "string",
                    "description": "The detailed description of the ambition. Simple HTML or plain text."
                  }
                },
                "required": [
                  "name",
                  "description"
                ],
                "additionalProperties": false
              }
            }
          },
          "required": [
            "name",
            "description",
            "ambitions"
          ],
          "additionalProperties": false
        }
      }
    },
    "required": [
      "planName",
      "planDescription",
      "positionName",
      "positionDescription",
      "focusName",
      "focusDescription",
      "capabilities"
    ],
    "additionalProperties": false
  }
};

