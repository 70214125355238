import React from 'react';
import { Selection, CommandBar, DetailsList, DetailsListLayoutMode, ICommandBarItemProps, Stack, useTheme, IColumn, Label, mergeStyles, IDetailsRowProps } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { IVistoPlan, KeyResultType, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem, VistoKind } from 'sp';
import { EditKeyResultValueDialog } from './EditKeyResultValueDialog';
import { makeGuidString } from 'shared/guid';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import strings from 'VistoWebPartStrings';

export const EditKeyResultList = (props: {
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  setKr: (kr: VistoKeyResultItem) => void;
  values: VistoKeyResultValueItem[];
  valueKind: KeyResultValueKind;
  onSetValues: (values: VistoKeyResultValueItem[]) => void;
  isRowBold: (item: any) => boolean;
}) => {

  const sorted = [...props.values].sort((a, b) => TextService.compareDateTime(a.valueDate, b.valueDate));

  const items = sorted
    .map(item => {
      const namedDate = PlanKeyresultsService.getKeyResultValueDateName(props.plan, item.valueDate);
      const date = namedDate
        ? TextService.format(strings.EditKeyResultList_ItemDate, { namedDate, date: TextService.formatDate(item.valueDate) })
        : TextService.formatDate(item.valueDate);
      const value = PlanKeyresultsService.formatKeyResultValue(item.value, props.kr, false);
      const comment = item.description;
      const key = item.guid;
      return { key, date, value, comment, item };
    });

  const dateColumnTitle = TextService.format(strings.EditKeyResultList_ColumnDate);
  const valueColumnTitle = props.kr.keyResultType === KeyResultType.Binary
    ? TextService.format(strings.EditKeyResultList_ColumnBinaryValue)
    : TextService.format(strings.EditKeyResultList_ColumnValue, { units: props.kr.units });
  const commentColumnTitle = TextService.format(strings.EditKeyResultList_ColumnComment);

  const columns: IColumn[] = [
    {
      key: 'date',
      name: dateColumnTitle,
      fieldName: 'date',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true
    },
    {
      key: 'value',
      name: valueColumnTitle,
      fieldName: 'value',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true
    },
    {
      key: 'comment',
      name: commentColumnTitle,
      fieldName: 'comment',
      minWidth: 200,
      isResizable: true
    }
  ];

  const [selectedItems, setSelectedItems] = React.useState<VistoKeyResultValueItem[]>([]);
  const [isNewValueDialogVisible, setIsNewValueDialogVisible] = React.useState(false);
  const [isEditValueDialogVisible, setIsEditValueDialogVisible] = React.useState(false);

  const makeNewKrv = (selectedItem?: VistoKeyResultValueItem) => {
    return {
      kind: VistoKind.KRV,
      guid: makeGuidString(),
      krGuid: props.kr.guid,
      valueKind: props.valueKind,
      valueDate: TextService.getDate(props.plan.statusDate),
      value: undefined,
    };
  };

  const [newKrv, setNewKrv] = React.useState(makeNewKrv());

  const selection = React.useRef(new Selection({
    onSelectionChanged: () => {
      setSelectedItems(selection.current.getSelection().map(x => x['item']));
    }
  }));

  const addNewButtonNameId = props.valueKind === KeyResultValueKind.Actual
    ? strings.EditKeyResultList_ButtonAddActual
    : strings.EditKeyResultList_ButtonAddTarget;

  const menuItems: ICommandBarItemProps[] = [
    {
      key: 'addnew',
      text: TextService.format(addNewButtonNameId),
      iconProps: { iconName: 'Add' },
            onClick: () => {
        setNewKrv(makeNewKrv(selectedItems[0]));
        setIsNewValueDialogVisible(true);
      }
    },
    {
      key: 'edit',
      text: TextService.format(strings.EditKeyResultList_ButtonEdit),
      iconProps: { iconName: 'Edit' },
      disabled: !(selectedItems.length === 1),
      onClick: () => {
        setIsEditValueDialogVisible(true);
      }
    },
    {
      key: 'delete',
      text: TextService.format(strings.EditKeyResultList_ButtonDelete),
      iconProps: { iconName: 'Delete' },
      disabled: !(selectedItems.length > 0),
      onClick: () => {
        onDeleteResultValue();
      }
    },
  ];

  const onSetValues = (newValues: VistoKeyResultValueItem[]) => {
    newValues = PlanKeyresultsService.sortKeyResultValues(newValues);
    props.onSetValues(newValues);
    setSelectedItems(newValues.filter(x => selectedItems.some(s => s.guid === x.guid)));
  };

  const onDeleteResultValue = () => {
    const newValues = props.values.filter(x => !selectedItems.find(t => t.guid === x.guid));
    onSetValues(newValues);
  };

  const onNewKeyResultValue = (changed: boolean, krv: VistoKeyResultValueItem) => {
    setIsNewValueDialogVisible(false);
    if (changed) {
      const newValues = [...props.values, krv];
      onSetValues(newValues);
    }
  };

  const onEditKeyResultValue = (changed: boolean, krv: VistoKeyResultValueItem) => {
    setIsEditValueDialogVisible(false);
    if (changed) {
      const foundIndex = props.values.findIndex(x => x.guid === krv.guid);
      const newValues = [...props.values];
      newValues.splice(foundIndex, 1, krv);
      onSetValues(newValues);
    }
  };

  const listWrapperStyle = mergeStyles({
    border: 0,
    minHeight: 100,
    maxHeight: '25vh',
    overflow: 'auto'
  });

  const getRowProps = (rowProps?: IDetailsRowProps) => {
    if (props.isRowBold(rowProps.item.item)) {
      return { ...rowProps, styles: { root: { fontWeight: 'bold' } } };
    } else {
      return rowProps;
    }
  };

  return (
    <>
      <CommandBar styles={{ root: { padding: 0 } }} items={menuItems} />
      <Stack data-is-scrollable='true' className={listWrapperStyle}>
        <DetailsList
          compact
          items={items}
          columns={columns}
          selection={selection.current}
          styles={{
            headerWrapper: {
              '.ms-DetailsHeader': {
                paddingTop: 0
              }
            }
          }}
          setKey='set'
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          onItemInvoked={() => setIsEditValueDialogVisible(true)}
          onRenderRow={(props, defaultRender) => defaultRender(getRowProps(props))}
        />
      </Stack>
      {isNewValueDialogVisible && <EditKeyResultValueDialog plan={props.plan} kr={props.kr} krv={newKrv} values={props.values} onDismiss={onNewKeyResultValue} />}
      {isEditValueDialogVisible && <EditKeyResultValueDialog plan={props.plan} kr={props.kr} krv={selectedItems[0]} values={props.values} onDismiss={onEditKeyResultValue} />}
    </>
  );
};
