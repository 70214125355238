import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Pivot, PivotItem, PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import { FileService } from 'services/FileService';
import { NotificationType } from 'services/Notify';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IPlanItems, IVistoPlan, IVistoListItemWithAssignee, VistoKind } from 'sp';
import { parseJSON } from 'shared/parse';
import { SharepointUserResolver } from 'services/SharepointUserResolver';
import { InfoBar, useErrorInfo } from 'components';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { LanguageDropdown } from 'components/LanguageDropdown';
import { PlanWizardCreateNewOperation } from './PlanWizardTypes';
import { NewPlanCardList } from './NewPlanCardList';
import { IWebFilePickerResult, WebFilePicker } from './WebFilePicker';
import { StorageService } from 'services/StorageService';
import { ITemplate } from 'services/ITemplate';
import { LicenseService } from 'services/LicenseService';
import { PlanTemplateService } from 'services/PlanTemplateService';

export function PlanWizardCreateNewPage(props: {
  siteUrl: string;
  planId: string;

  createNewOperation: PlanWizardCreateNewOperation;
  setCreateNewOperation(val: PlanWizardCreateNewOperation);

  planName: string;
  setPlanName: (planName: string) => void;

  planLanguage: string;
  setPlanLanguage: (planLanguage: string) => void;

  planJson: IVistoPlan;
  setPlanJson: (plan: IVistoPlan) => void;

  templates: ITemplate[];
  selectedTemplateId: string;
  setSelectedTemplateId: (val) => void;

  orgTemplates: ITemplate[];
  selectedOrgTemplateId: string;
  setSelectedOrgTemplateId: (val) => void;

  importedFileName: string;
  setImportedFileName: (importedFileName: string) => void;

  assistantText: string;
  setAssistantText: (assistantText: string) => void;

  getPlanNameErrorMessage: (newPlanName: string) => string;
}) {

  const [planName, _setPlanName] = React.useState(props.planName);
  const setPlanName = (val: string) => {
    _setPlanName(val);
    props.setPlanName(val.trim());
  };

  const planLanguage = props.planLanguage;
  const setPlanLanguage = (val: string) => {
    const isDefaultPlanName = planName === TextService.format(strings.PlanConfiguration_NewPlanName);
    props.setPlanLanguage(val);
    if (isDefaultPlanName) {
      setPlanName(TextService.format(strings.PlanConfiguration_NewPlanName));
    }
  }

  const [errorInfo, setErrorInfo] = useErrorInfo();
  const [isImporting, setIsImporting] = React.useState(false);

  const removeExternalLinks = (items: IPlanItems) => {
    let removedCount = 0;
    for (const key in items) {
      const item: any = items[key];
      if (!keepLinks && item.sourceItemUrl) {
        item.sourceItemUrl = null;
        ++removedCount;
      }
    }
    return removedCount;
  };

  const removeInvalidUserNames = (actions: IVistoListItemWithAssignee[], invalidUserNames: string[]) => {
    const assignedActions = actions.filter(a => a.assignedTo);
    for (const assignedAction of assignedActions) {
      let i = 0;
      while (i < assignedAction.assignedTo.length) {
        const val = assignedAction.assignedTo[i];
        if (val && invalidUserNames.indexOf(val.userName) >= 0) {
          assignedAction.assignedTo.splice(i, 1);
        } else {
          ++i;
        }
      }
    }
  };

  const [importedFile, setImportedFile] = React.useState<{ fileName: string, content: string }>(null);

  const [keepLinks, setKeepLinks] = React.useState(false);

  const reloadImportedFile = async (options: { initialize: boolean }) => {
    try {
      setIsImporting(true);
      setErrorInfo(null);
      props.setImportedFileName(importedFile.fileName);

      const templatePlan: IVistoPlan = importedFile.content.match(/\{\{\w+\}\}/)
        ? PlanTemplateService.createTemplatePlan({ id: '', name: planName, planJson: importedFile.content })
        : parseJSON(importedFile.content);

      const loadedPlan: IVistoPlan = {
        planId: props.planId,
        ...templatePlan,
        siteUrl: props.siteUrl,
      };

      if (options.initialize) {
        setPlanName(loadedPlan.name);
        const loadedPlanSettings = PlanSettingsService.getPlanSettings(loadedPlan);
        props.setPlanLanguage(loadedPlanSettings.language);
      }

      let warning = '';

      PlanDataService.removeInvalidItems(loadedPlan.items);
      PlanDataService.removeAssigneeIds(loadedPlan.items);

      const removedCount = removeExternalLinks(loadedPlan.items);
      if (removedCount) {
        warning += TextService.format(strings.PlanWizardImport_LinksIgnored, { count: removedCount });
      }

      const assignedItems = PlanDataService.getItemsHaving(loadedPlan.items, (a: IVistoListItemWithAssignee) => !!a.assignedTo);
      try {
        await SharepointUserResolver.resolveUserIds(loadedPlan, assignedItems);
      } catch (error) {
        if (error.invalid_user_names) {
          removeInvalidUserNames(assignedItems, error.invalid_user_names);
          warning += TextService.format(strings.PlanWizardImport_UsersIgnored, { users: TextService.formatList(error.invalid_user_names) });
        }
      }
      props.setPlanJson(loadedPlan);
      if (warning) {
        setErrorInfo({ type: NotificationType.warn, message: TextService.format(strings.PlanWizardImport_Ignored), error: warning });
      }
      setIsImporting(false);
    } catch (error) {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.PlanWizardImport_ErrorImportingFile), error });
      setIsImporting(false);
    }
  };

  React.useEffect(() => {
    if (importedFile) {
      reloadImportedFile({ initialize: true});
    }
  }, [importedFile]);

  React.useEffect(() => {
    if (importedFile) {
      reloadImportedFile({ initialize: false});
    }
  }, [keepLinks]);

  const onBrowse = async () => {
    try {
      const result = await FileService.import('application/json');
      setImportedFile(result);
    } catch (error) {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.PlanWizardImport_ErrorReadingFile), error });
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const onSave = async (val: IWebFilePickerResult) => {
    for (const result of val.results) {

      try {

        if (!result.fileAbsoluteUrl) {
          const fileConent = await result.downloadFileContent();
          setImportedFile({ fileName: result.fileName, content: await fileConent.text() });          
        } else {
          const fileConent = await StorageService.get(props.siteUrl).getFile(result.fileAbsoluteUrl);
          setImportedFile({ fileName: result.fileName, content: await fileConent.text() });
        }
      } catch (error) {
        setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.PlanWizardImport_ErrorImportingFile), error });
      }
    }
  };

  return (
    <Stack grow tokens={{ childrenGap: 'm' }}>
      <InfoBar {...errorInfo} />

      <Stack horizontal tokens={{ childrenGap: 'm' }}>
        <Stack.Item grow>
          <TextField
            autoComplete='off'
            required
            disabled={isImporting}
            label={TextService.format(strings.PlanWizardImportPage_PlanName)}
            value={planName}
            onChange={(_, val) => setPlanName(val)}
            errorMessage={props.getPlanNameErrorMessage(planName)}
          />
        </Stack.Item>
        <Stack.Item>
          <LanguageDropdown
            language={planLanguage}
            setLanguage={setPlanLanguage}
          />
        </Stack.Item>

      </Stack>

      <Pivot style={{ minHeight: 210 }} selectedKey={props.createNewOperation} onLinkClick={(item) => props.setCreateNewOperation(item.props.itemKey as PlanWizardCreateNewOperation)}>
        <PivotItem itemKey={'CreateNewText'} headerText={strings.PlanWizardCreate_TabAssistant}>
          <TextField 
            multiline 
            autoFocus
            placeholder='Please describe what plan you want to create'
            rows={5} 
            value={props.assistantText} 
            onChange={(_, val) => props.setAssistantText(val)} 
          />
        </PivotItem>
        <PivotItem itemKey={'CreateNewBlank'} headerText={TextService.format(strings.PlanWizardCreate_TabFromTemplate)}>
          <NewPlanCardList templates={props.templates} selectedId={props.selectedTemplateId} setSelectedId={props.setSelectedTemplateId} />
        </PivotItem>
        <PivotItem itemKey={'CreateNewFromFile'} headerText={TextService.format(strings.PlanWizardCreate_TabFromFile)}>
          <Stack grow tokens={{ padding: 'm s1', childrenGap: 's1' }}>
            <Stack  tokens={{ childrenGap: 's1' }}>
              <TextField disabled={isImporting} readOnly value={props.importedFileName} />
              <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 's1' }}>
                <WebFilePicker isOpen={isOpen} onCancel={() => setIsOpen(false)} onSave={onSave} />
                <PrimaryButton disabled={isImporting} text={TextService.format(strings.PlanWizardImportPage_ButtonBrowse)} onClick={onBrowse} />
              </Stack>
            </Stack>
            <Toggle
              label={TextService.format(strings.PlanWizardImport_RemoveLinks)}
              disabled={!props.importedFileName || isImporting}
              checked={!keepLinks}
              onChange={() => setKeepLinks(!keepLinks)}
            />
          </Stack>
        </PivotItem>
        {LicenseService.license?.templatesEnabled && props.orgTemplates.length > 0 &&
        <PivotItem itemKey={'CreateNewOrg'} headerText={TextService.format(strings.PlanWizardCreate_TabOrg)}>
          <NewPlanCardList templates={props.orgTemplates} selectedId={props.selectedOrgTemplateId} setSelectedId={props.setSelectedOrgTemplateId} />
        </PivotItem>}
      </Pivot>
    </Stack>
  );
}
