import * as strings from 'VistoWebPartStrings';
import * as nacl from 'tweetnacl';
import * as naclutil from 'tweetnacl-util';
import { trackClient } from 'shared/clientTelemetry';
import { TextService } from 'services/TextService';
import { api } from 'shared/api';
import { IVistoPlan } from 'sp/VistoPlan';
import { UrlService } from 'shared/urlService';
import { parseJSON } from 'shared/parse';
import { ILicenseInfo } from '../../shared/ILicenseInfo';

const keys = {
  publicBase64: 'o1q4tpCy04606H+qx5yw8X2xuIX7jv0WqAhaVl16Yfg='
};

export interface ILicenseKey {
  site: string;
  planId: string;
  expiration: string;
  projectEnabled: boolean;
  plannerEnabled: boolean;
  devopsEnabled: boolean;
  effectsEnabled: boolean;
  customStringsEnabled: boolean;
  okrEnabled: boolean;
  matrixEnabled: boolean;
  templatesEnabled: boolean;
}

export class LicenseService {

  public static license: ILicenseInfo = null;
  public static setLicense(license: ILicenseInfo) {
    this.license = license;
  }

  public static getPlanDefaultDesktopLicense(): ILicenseInfo {
    return {
      infoText: undefined,
      notificationText: undefined,
      bubbleNotificationText: undefined,
      isLocked: false,
      expireDate: undefined,
      plannerEnabled: false,
      devopsEnabled: false,
      projectEnabled: false,
      effectsEnabled: true,
      customStringsEnabled: true,
      okrEnabled: true,
      matrixEnabled: true,
      templatesEnabled: true,
    };
  };

  public static getPlanDefaultLocalLicense(newPlan: IVistoPlan): ILicenseInfo {

    const now = TextService.getDate(new Date());
    const createdDate = TextService.getDate(newPlan.createdDate);
    const expireDate = createdDate && TextService.addDays(createdDate, 255);

    const infoTextFragments: string[] = [];
    if (expireDate) {
      infoTextFragments.push(TextService.format(strings.License_PlanExpires, { date: TextService.formatDate(expireDate) }));
    }
    infoTextFragments.push(TextService.format(strings.License_PlanContact));

    const infoText = infoTextFragments.join(' ');

    return {
      isLocked: false, // now > expireDate,
      infoText: infoText,
      notificationText: undefined,
      bubbleNotificationText: undefined,
      expireDate: expireDate,
      projectEnabled: false,
      plannerEnabled: false,
      devopsEnabled: false,
      effectsEnabled: false,
      customStringsEnabled: false,
      okrEnabled: true,
      matrixEnabled: true,
      templatesEnabled: true,
    }
  }

  public static hasOfflineLicense(newPlan: IVistoPlan) {
    return !!newPlan.licenseKey;
  }

  public static getOfflinePlanLicense(newPlan: IVistoPlan): ILicenseInfo {

    const decoded = LicenseService.decodeLicenseKey(newPlan.licenseKey);
    const licenseError = LicenseService.getLicenseError(decoded, newPlan.siteUrl, newPlan.planId);

    const licenseStatus = licenseError
      ? TextService.format(strings.LicenseStatus_Invalid)
      : TextService.format(strings.MainFrame_LicenseValid);

    return {
      isLocked: false,
      infoText: licenseStatus,
      notificationText: undefined,
      bubbleNotificationText: undefined,
      expireDate: decoded?.expiration && new Date(decoded.expiration),
      projectEnabled: decoded.projectEnabled,
      plannerEnabled: decoded.plannerEnabled,
      devopsEnabled: decoded.devopsEnabled,
      effectsEnabled: decoded.effectsEnabled,
      customStringsEnabled: decoded.customStringsEnabled,
      okrEnabled: decoded.okrEnabled,
      matrixEnabled: decoded.matrixEnabled,
      templatesEnabled: decoded.templatesEnabled,
    };
  }

  public static getLocalPlanLicense(newPlan: IVistoPlan) {

    if (this.hasOfflineLicense(newPlan)) {
      return this.getOfflinePlanLicense(newPlan);
    }

    if (UrlService.isLocalUrl(newPlan.siteUrl)) {
      const isPlanDesktop = window.location.protocol === 'file:';
      return isPlanDesktop
        ? this.getPlanDefaultDesktopLicense()
        : this.getPlanDefaultLocalLicense(newPlan);
    }
  }

  public static getLicenseError(license: ILicenseKey, absoluteUrl: string, planId: string): string {

    if (!license)
      return TextService.format(strings.InvalidLicenseStatus_Missing);

    const licensedSite = license.site;
    if (licensedSite !== absoluteUrl) {
      trackClient.error(`license key site mismatch, current: '${absoluteUrl}' license: '${licensedSite}'`);
      return TextService.format(strings.InvalidLicenseStatus_SiteMismatch);
    }

    const currentPlanId = planId;
    const licensedPlanId = license.planId;
    if (license.planId !== planId) {
      trackClient.error(`plan id mismatch, expected '${currentPlanId}' license '${licensedPlanId}'`);
      return TextService.format(strings.InvalidLicenseStatus_PlanMismatch);
    }
  }

  public static decodeLicenseKey(key: string): ILicenseKey {

    if (key && key.indexOf('.') > 0) {
      const [msgBase64, signatureBase64] = key.split('.');
      if (msgBase64 && signatureBase64) {
        const msg = naclutil.decodeBase64(msgBase64);
        const sig = naclutil.decodeBase64(signatureBase64);

        const publicKey = naclutil.decodeBase64(keys.publicBase64);

        if (nacl.sign.detached.verify(msg, sig, publicKey)) {
          const json = naclutil.encodeUTF8(msg);
          return JSON.parse(json);
        }
      }
    }
  }

  private static getCacheKey(plan: IVistoPlan) {
    return `${plan.siteUrl}/${plan.planId}/license`;
  }

  public static getCachedLicense(plan: IVistoPlan): ILicenseInfo {
    const licenseCacheKey = this.getCacheKey(plan);
    const cachedLicenseJson = localStorage.getItem(licenseCacheKey);
    if (cachedLicenseJson) {
      try {
        return parseJSON(cachedLicenseJson);
      } catch (err) {
        trackClient.warn(`Unable to read the cached license`, err);
      }
    }    
  }

  public static setCachedLicense(plan: IVistoPlan, license: ILicenseInfo) {
    const licenseCacheKey = this.getCacheKey(plan);
    localStorage.setItem(licenseCacheKey, JSON.stringify(license));
  }
}
