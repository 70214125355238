import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { EditCustomStringsDialog } from './EditCustomStringsDialog';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import { Checkbox, DatePicker, DefaultButton, Dropdown, Separator, Stack, TextField } from '@fluentui/react';
import { BasicDialog } from './common';
import { trackClient } from 'shared/clientTelemetry';
import { LanguageDropdown } from 'components/LanguageDropdown';
import { LicenseService } from 'services/LicenseService';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { IVistoPlan } from 'sp';

export function PlanViewSettingsDialog(props: {
  plan: IVistoPlan;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('PlanViewSettingsDialog'), []);

  const { dispatchCommand, notify, isPlanEditEnabled } = React.useContext(AppContext);

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);

  const [showIndicators, setShowIndicators] = React.useState(planSettings.showIndicators || typeof (planSettings.showIndicators) === 'undefined');
  const [showKpiIndicators, setShowKpiIndicators] = React.useState(planSettings.showKpiIndicators || typeof (planSettings.showKpiIndicators) === 'undefined');
  const [showOwnerIndicators, setShowOwnerIndicators] = React.useState(planSettings.showOwnerIndicators || typeof (planSettings.showOwnerIndicators) === 'undefined');
  const [showProgressIcons, setShowProgressIcons] = React.useState(planSettings.showProgressIcons || typeof (planSettings.showProgressIcons) === 'undefined');
  const [progressThresholdRed, setProgressThresholdRed] = React.useState(typeof (planSettings.progressThresholdRed) === 'number' ? planSettings.progressThresholdRed : -15);
  const [progressThresholdGreen, setProgressThresholdGreen] = React.useState(typeof (planSettings.progressThresholdGreen) === 'number' ? planSettings.progressThresholdGreen : -3);
  const [percentOpacity, setPercentOpacity] = React.useState(typeof (planSettings.percentOpacity) === 'number' ? planSettings.percentOpacity : 40);
  const [useFixedDate, setUseFixedDate] = React.useState(planSettings.useFixedDate);
  const [fixedDate, setFixedDate] = React.useState(planSettings.fixedDate);

  const [memberEditLevel, setMemberEditLevel] = React.useState(typeof (planSettings.memberEditLevel) === 'undefined' ? 'full' : planSettings.memberEditLevel);
  const [guestEditLevel, setGuestEditLevel] = React.useState(typeof (planSettings.guestEditLevel) === 'undefined' ? 'full' : planSettings.guestEditLevel);

  const [language, setLanguage] = React.useState(planSettings.language);

  const [customStrings, setCustomStrings] = React.useState(planSettings.customStrings);

  const [isEditCustomStringsDialogVisible, setEditCustomStringsDialogVisible] = React.useState(false);

  const update = () => dispatchCommand(PlanSettingsService.makeSetSettingCommand(props.plan, {
    showIndicators,
    showKpiIndicators,
    showOwnerIndicators,
    showProgressIcons,
    progressThresholdRed,
    progressThresholdGreen,
    language,
    useFixedDate,
    fixedDate,
    customStrings,
    percentOpacity,
    memberEditLevel,
    guestEditLevel
  }, notify), {
    wrap: false
  });

  const onEditCustomStringsDialogClosed = async (newCustomStrings: any) => {
    setCustomStrings({ ...planSettings.customStrings, [language]: newCustomStrings });
  };

  const progressThresholdRedValid = (typeof progressThresholdRed === 'number' && -100 <= progressThresholdRed && progressThresholdRed <= 100);
  const progressThresholdGreenValid = (typeof progressThresholdGreen === 'number' && -100 <= progressThresholdGreen && progressThresholdGreen <= 100);
  const progressThresholdOrderValid = typeof progressThresholdRed === 'number' && typeof progressThresholdGreen === 'number' && progressThresholdRed <= progressThresholdGreen;

  const progressThresholdRedMessage = !progressThresholdRedValid
    ? TextService.format(strings.PlanViewSettingsDialog_ErrorRange)
    : !progressThresholdOrderValid
      ? TextService.format(strings.PlanViewSettingsDialog_ErrorRedOrder)
      : null;

  const progressThresholdGreenMessage = !progressThresholdGreenValid
    ? TextService.format(strings.PlanViewSettingsDialog_ErrorRange)
    : !progressThresholdOrderValid
      ? TextService.format(strings.PlanViewSettingsDialog_ErrorGreenOrder)
      : null;

  const percentOpacityValid = (typeof percentOpacity === 'number' && 0 <= percentOpacity && percentOpacity <= 100);
  const percentOpacityErrorMessage = !percentOpacityValid && TextService.format(strings.PlanViewSettingsDialog_ErrorOpacity);

  const isButtonOkDisabled = (useFixedDate && !fixedDate)
    || !progressThresholdRedValid
    || !progressThresholdGreenValid
    || !progressThresholdOrderValid;

  const permissionOptions = [
    { key: 'full', text: TextService.format(strings.PlanPermissions_Full) },
    { key: 'data', text: TextService.format(strings.PlanPermissions_Data) },
    { key: 'view', text: TextService.format(strings.PlanPermissions_View) },
  ];

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      title={TextService.format(strings.PlanViewSettingsDialog_Title)}
      buttonOkAction={update}
      buttonOkText={TextService.format(strings.ButtonSave)}
      isButtonOkDisabled={isButtonOkDisabled}
      buttonOkBusyText={TextService.format(strings.ButtonSaving)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      content={
        <Stack style={{ minHeight: 350 }} tokens={{ childrenGap: 'm' }}>
          <LanguageDropdown language={language} setLanguage={setLanguage} />
          {LicenseService.license?.customStringsEnabled && isPlanEditEnabled &&
            <Stack horizontalAlign='end'>
              <DefaultButton onClick={() => setEditCustomStringsDialogVisible(true)} text={strings.PlanViewSettingsDialog_CustomStringsButton} />
            </Stack>
          }
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <Stack tokens={{ childrenGap: 'm' }}>
              {LicenseService.license?.okrEnabled && <Checkbox checked={showKpiIndicators} onChange={(_, val) => setShowKpiIndicators(val)} label={TextService.format(strings.PlanSettings_ShowKpiIndicators)} />}
              <Checkbox 
                disabled={!isPlanEditEnabled}
                checked={showOwnerIndicators} 
                onChange={(_, val) => setShowOwnerIndicators(val)} 
                label={TextService.format(strings.PlanSettings_ShowOwners)} 
              />

              <TextField
                type='number'
                min={0}
                max={100}
                disabled={!isPlanEditEnabled}
                label={TextService.format(strings.PlanSettings_VisualFilterOpacity)}
                value={percentOpacity?.toString()}
                onChange={(_, val) => setPercentOpacity(+val)}
                errorMessage={percentOpacityErrorMessage}
              />

              <Stack horizontalAlign='start' tokens={{ childrenGap: 's1' }}>
                <Checkbox 
                  disabled={!isPlanEditEnabled}
                  checked={useFixedDate} 
                  onChange={(_, val) => setUseFixedDate(val)} 
                  label={TextService.format(strings.PlanSettings_UseFixedDate)} 
                />
                <DatePicker
                  strings={TextService.datePickerStrings}
                  firstDayOfWeek={TextService.firstDayOfWeek}
                  formatDate={d => TextService.formatDate(d)}
                  disabled={!useFixedDate || !isPlanEditEnabled}
                  value={fixedDate}
                  allowTextInput={true}
                  onSelectDate={val => setFixedDate(val)}
                />
                {isEditCustomStringsDialogVisible && <EditCustomStringsDialog
                  customStrings={customStrings?.[language]}
                  onSave={onEditCustomStringsDialogClosed}
                  onDismiss={() => setEditCustomStringsDialogVisible(false)}
                />}
              </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 'm' }}>
              <Checkbox 
                disabled={!isPlanEditEnabled}
                checked={showIndicators} 
                onChange={(_, val) => setShowIndicators(val)} 
                label={TextService.format(strings.PlanSettings_ShowProgressIndicators)} 
              />
              <Checkbox 
                disabled={!isPlanEditEnabled}
                checked={showProgressIcons} 
                onChange={(_, val) => setShowProgressIcons(val)} 
                label={TextService.format(strings.PlanSettings_ShowProgressIcons)} 
              />
              <TextField
                label={TextService.format(strings.PlanViewSettingsDialog_ThresholdGreen)}
                disabled={!isPlanEditEnabled}
                min={-100} max={+100} type='number'
                value={progressThresholdGreen?.toString()}
                onChange={(_, val) => setProgressThresholdGreen(+val)}
                errorMessage={progressThresholdGreenMessage}
                suffix='%'
              />
              <TextField
                label={TextService.format(strings.PlanViewSettingsDialog_ThresholdRed)}
                disabled={!isPlanEditEnabled}
                min={-100} max={+100} type='number'
                value={progressThresholdRed?.toString()}
                onChange={(_, val) => setProgressThresholdRed(+val)}
                errorMessage={progressThresholdRedMessage}
                suffix='%'
              />
            </Stack>
          </Stack>

          <Dropdown 
            label={TextService.format(strings.PlanPermissions_LabelMembers)} 
            options={permissionOptions}
            selectedKey={memberEditLevel || 'full'}
            disabled={!isPlanEditEnabled}
            onChange={(_, val) => setMemberEditLevel(val.key as any)}
          />

          <Dropdown 
            label={TextService.format(strings.PlanPermissions_LabelGuests)} 
            options={permissionOptions}
            selectedKey={guestEditLevel || 'full'}
            disabled={!isPlanEditEnabled}
            onChange={(_, val) => setGuestEditLevel(val.key as any)}
          />

        </Stack>
      }
    />
  );

}
