import { hOP } from '@pnp/core';
import { HttpRequestError } from '@pnp/queryable';
import { trackClient } from 'shared/clientTelemetry';
import { stringifyError } from 'shared/parse';

export class PnpService {
  public static async getErrorMessage(errorTiele: string, e: any) {
    trackClient.error(errorTiele, e);
    if (hOP(e, 'isHttpRequestError')) {
      const data = await (<HttpRequestError>e).response.json();
      // parse this however you want
      if (typeof data['odata.error'] === 'object') {
        return data['odata.error'].message.value;
      } if (typeof data.error === 'object') {
        return data.error.message;
      } else {
        return stringifyError(e);
      }
    } else {
      return stringifyError(e);
    }
  }

}
