import * as React from 'react';
import { Callout, CommandButton, DefaultButton, FontSizes, IconButton, IContextualMenuItem, IContextualMenuProps, Pivot, PivotItem, Stack, Text, useTheme } from '@fluentui/react';
import { IVistoPlan, VistoKeyResultItem, VistoKind } from 'sp';
import { IAppContext, AppContext } from 'services/AppContext';
import { ICommand } from 'services/ICommand';
import { EnvContext, isTeams } from 'services/EnvContext';
import { IPropertyBag } from 'services/IPropertyBag';
import { INotification, NotificationType } from 'services/Notify';
import { PlanDataService } from 'services/PlanDataService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { clearHighlights, updateDiagramFromPlanWithDates, updateHighlights } from 'frames/TopFrame/drawing/DrawingUpdate';
import { mxgraph } from 'ts-mxgraph-typings';
import { Editor, EditorUi, mx, ViewAlignment } from 'frames/TopFrame/drawing/common';
import { UpgradePlanPlaceholder, useErrorInfo } from 'components';
import { StatusBarZoom } from 'frames/TopFrame/statusbar/StatusBarZoom';
import { PersonaBar } from 'components/PersonaBar';
import { ToggleEditButton } from 'components/ToggleEditButton';
import { api } from 'shared/api';
import { trackClient } from 'shared/clientTelemetry';
import { GettingStartedDialog } from 'dialogs/GettingStartedDialog';
import { PropertyBagService } from 'services/PropertyBagService';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { setIsLoading } from 'services/LoadingIndicator';
import { LicenseDialog } from 'dialogs';
import { PlanViewSettingsDialog } from 'dialogs/PlanViewSettingsDialog';

import { MenuItems } from './TopFrame/MenuItems';
import { TopFrame } from './TopFrame/TopFrame';
import { MatrixFrame } from './MatrixFrame/MatrixFrame';
import { PlannerConfigurationService, PlannerPlanWizard } from 'integrations/planner';
import { ProjectWizard } from 'integrations/project';
import { DevOpsService, DevOpsWizard } from 'integrations/devops';
import { ExportPlanJsonDialog, ExportPlanSvgDialog, ExportPlanPngDialog } from 'dialogs/export';

import { NotificationBar } from 'components/NotificationBar';
import { LicenseService } from 'services/LicenseService';
import { ILicenseInfo } from 'shared/ILicenseInfo';
import { GanttFrame } from './GanttFrame/GanttFrame';
import { PrintService } from 'services/PrintService';
import { UserInfoService } from 'services/UserInfoService';
import { isConsentError, parseJSON, stringifyError } from 'shared/parse';
import { RefreshButton } from 'components/RefreshButton';
import { RefreshCommand } from 'services/RefreshCommand';
import { EditKeyResultValuesDialog } from 'dialogs/EditKeyResultValuesDialog';
import { isGuid } from 'shared/guid';
import { PlanValidationService } from 'services/PlanValidationService';
import { ActionListFrame } from './ActionListFrame/ActionListFrame';
import { UrlService } from 'shared/urlService';
import { Tutorial } from './TopFrame/Tutorial';
import { AuthService, UserTeamRole } from 'services/AuthService';
import { StorageCacheService } from 'services/StorageCacheService';
import { ApiService } from 'services/ApiService';
import { IRealtimeService } from 'services/IRealtimeService';
import { StatusBarMatrixZoom } from './TopFrame/statusbar/StatusBarMatrixZoom';
import { ExportPlanTemplateDialog } from 'dialogs/export/ExportPlanTemplateDialog';
import { ICommandOptions } from 'services/ICommandOptions';
import { DashboardDataService } from 'services/DashboardDataService';
import { IntegrationService } from 'services/IntegrationService';
import { NavigationFrameButton } from './NavigationFrame/NavigationFrameButton';
import { useEnvNavigate } from 'services/NavigationService';
import { useNotifications } from './hooks/NotificationsHook';
import { OkrFrame } from './OkrFrame/OkrFrame';
import { PlanVisualFilter } from './VisualFilter/PlanVisualFilter';
import { IVisualFilter } from './VisualFilter/IVisualFilter';
import { VisualFilterService } from './VisualFilter/VisualFilterService';
import { PlanGenerationService } from 'services/PlanGenerationService';
import { GenerateSidebar } from './TopFrame/sidebars/generate/GenerateSidebar';
import { IAssistatCard } from './IAssistatCard';
import { AssistantInstructions } from 'shared/AssistantInstructions';
import { PlanDrawingGenerationService } from 'services/PlanDrawingGenerationService';
import { IPlanGenerationUpdates } from 'services/PlanGenerationInterfaces';

const DEBOUNCE_TIME_MS = 2000;

/**
 * Implementation of the VistoTeams Tab content page
 */
export const MainFrame = (props: {
  source: { planId: string, siteUrl: string };
  getRealtimeService: (userId: string, containerId: string, callback: (msg: api.IWSMessage) => void) => IRealtimeService;
}) => {

  const theme = useTheme();

  trackClient.origin = UrlService.getOrigin(props.source.siteUrl);

  const isPlanLive = !props.source.planId.startsWith('https://');
  const isPlanDesktop = window.location.protocol === 'file:';
  const isPlanLocal = UrlService.isLocalUrl(props.source.siteUrl);

  if (isPlanLive && !isPlanDesktop) {
    trackClient.planId = props.source.planId;
  }

  const envContext = React.useContext(EnvContext);

  const [selectedTab, setSelectedTab] = React.useState('plan');

  const localStoragePlanKey = PlanDataService.getPlanLocalStorageKey(props.source.siteUrl, props.source.planId);
  const reopeningPlan = !localStorage.getItem(localStoragePlanKey);
  if (!reopeningPlan) {
    localStorage.removeItem(localStoragePlanKey);
  }

  const planReadOnlyRef = React.useRef(reopeningPlan);
  const appContextRef = React.useRef<IAppContext>(null);
  const planRef = React.useRef<IVistoPlan>(null);
  const editorUiRef = React.useRef<any>(null);

  const savePendingRef = React.useRef(false);
  const commandPendingRef = React.useRef(false);
  const afterSaveActionsRef = React.useRef<(() => void)[]>([]);
  const afterCommandActionsRef = React.useRef<(() => void)[]>([]);

  const [matrixReadOnly, setMatrixReadOnly] = React.useState(true);
  const [ganttReadOnly, setGanttReadOnly] = React.useState(true);
  const [actionsReadOnly, setActionsReadOnly] = React.useState(true);
  const [okrReadOnly, setOkrReadOnly] = React.useState(true);

  const [outdated, setOutdated] = React.useState(false);
  const [missing, setMissing] = React.useState('');

  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const notifications = useNotifications();
  /**
   * save logic
   */

  const [drawingDataToSave, setDrawingDataToSave] = React.useState<string>();

  const afterSave = () => {
    savePendingRef.current = false;
    for (const afterSaveAction of afterSaveActionsRef.current) {
      afterSaveAction();
    }
    afterSaveActionsRef.current = [];
  };

  const afterCommand = () => {
    commandPendingRef.current = false;
    for (const afterCommandAction of afterCommandActionsRef.current) {
      afterCommandAction();
    }
    afterCommandActionsRef.current = [];
  };

  const persistDrawingData = (newXmlData: string) => {
    const editorUi = editorUiRef.current;

    editorUi.editor.setModified(false);
    notifications.setStatus({ type: NotificationType.log, message: TextService.format(strings.StatusBar_SavingNow) });

    const plan = planRef.current;
    StorageService.get(plan.siteUrl).updatePlanItem(plan, { drawingXml: newXmlData }, null).then(
      () => {
        afterSave();
        plan.drawingRevision = plan.drawingRevision + 1;
        notifications.updateStatus();
        if (appContextRef.current.wsContext) {
          appContextRef.current.wsContext.setDrawingRevision(plan.drawingRevision);
        }
      }, error => {
        afterSave();
        notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.StatusBar_SaveError), error });
      }
    );
  };

  // data 
  const [activeKeyResult, setActiveKeyResult] = React.useState<VistoKeyResultItem>(null);

  const { hostKind } = React.useContext(EnvContext);
  const navigate = useEnvNavigate(isTeams(hostKind));

  const onIconClick = (param: string) => {
    if (isGuid(param)) {
      setActiveKeyResult(PlanDataService.getItemByGuid<VistoKeyResultItem>(planRef.current.items, param));
    } else {
      navigate(param, `visplan`);
    }
  };

  const updateDiagramFromPlan = (graph: mxgraph.mxGraph, plan: IVistoPlan, repaintCellIds?: string[]) => {
    if (planReadOnlyRef.current) {
      const savedFilter = VisualFilterService.getSavedFilter(plan);
      VisualFilterService.apply(graph, plan, savedFilter);
      setVisualFilter(savedFilter)
    }

    updateDiagramFromPlanWithDates(graph, plan, appContextRef.current?.propertyBag, repaintCellIds, onIconClick);
  };

  const resetAssistant = (newPlan: IVistoPlan) => {
    setAssistantCards([
      { role: 'system', content: AssistantInstructions },
      {
        role: 'assistant', content: JSON.stringify(PlanGenerationService.getTextPlan(newPlan)),
        debugPlan: UrlService.isDev ? { ...newPlan, previewPng: undefined } : undefined
      }
    ])
  }

  const loadPlanData = async (editorUi: EditorUi, newPlan: IVistoPlan) => {

    const newPlanSettings = PlanSettingsService.getPlanSettings(newPlan);
    ensureUiLanguage(newPlanSettings.language);

    if (isPlanLive) {

      const editor = editorUi.editor;
      const graph = editor.graph;

      try {
        setIsLoading(TextService.format(strings.MainTab_LoadingPlanData));
        newPlan = await StorageService.get(newPlan.siteUrl).loadPlanData(newPlan, appContextRef.current.notify);
      } catch (error) {
        notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.MainFrame_ErrorLoadingItems), error });
      }

      const newPlanXml = mx.getGraphXml(editor);
      if (newPlan.drawingXml !== newPlanXml) {
        newPlan.drawingXml = newPlanXml;
        setDrawingDataToSave(newPlanXml);
      }

      PlanValidationService.validate(newPlan, appContextRef.current.notify);

      resetAssistant(newPlan);

      if (PlannerConfigurationService.isConnectedToPlanner(newPlan)) {
        PlannerConfigurationService.validatePlannerConnection(appContextRef.current.notify, () => {
          appContextRef.current.isPlanEditEnabled = true;
          forceUpdate();
        }).then(valid => {
          if (!valid) {
            appContextRef.current.isPlanEditEnabled = false;
            forceUpdate();
          }
        })
      }

      RefreshCommand.notifySyncDate(newPlan, appContextRef.current.notify);

      updateDiagramFromPlan(graph, newPlan);
    } else {

      appContextRef.current.notify.addNotification({
        type: NotificationType.log,
        message: TextService.format(strings.StatusText_FixedCopyAsOfDate, {
          date: TextService.formatDateTime(newPlan.statusDate)
        })
      });

      updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
    }

    editorUiRef.current = editorUi;
    setCurrentPlan(newPlan);

    setIsLoading('');
    forceUpdate();
  };

  // debounce

  React.useEffect(() => {
    if (drawingDataToSave && !planReadOnlyRef.current) {
      notifications.setStatus({ type: NotificationType.log, message: TextService.format(strings.StatusBar_SavePending) });
      savePendingRef.current = true;
      const timer = setTimeout(() => persistDrawingData(drawingDataToSave), DEBOUNCE_TIME_MS);
      return () => clearTimeout(timer);
    }
  }, [drawingDataToSave]);

  /**
   * commands logic
   */

  const executeAction = (
    action: (plan: IVistoPlan, editor: Editor) => Promise<IVistoPlan>,
    message: string,
    options: ICommandOptions,
    details?: (plan: IVistoPlan) => string,
  ): Promise<IVistoPlan> => {

    return new Promise((resolve, reject) => {

      commandPendingRef.current = true;

      if (options?.wrap) {
        setIsLoading('Processing...');
      }

      action(planRef.current, editorUiRef.current?.editor).then((newPlan) => {

        if (editorUiRef.current?.editor?.modified && isPlanLive) {
          const graphXml = mx.getGraphXml(editorUiRef.current.editor);
          newPlan.drawingXml = graphXml;
          setDrawingDataToSave(graphXml);
        }

        setCurrentPlan(newPlan);

        if (options?.wrap) {
          setIsLoading('');
        }

        notifications.addNotification({
          type: NotificationType.success,
          message: message ? TextService.format(strings.Command_Succeeded, { message }) : null,
          error: details ? details(newPlan) : undefined
        });

        afterCommand();
        resolve(newPlan);

      }, (error) => {

        afterCommand();
        trackClient.error(`error executing command ${message}`, error);
        notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.Command_Failed, { message }), error });
        if (options?.wrap) {
          setIsLoading('');
          resolve(planRef.current);
        } else {
          reject(error);
        }
      });
    });
  };

  const doDispatchCommand = async (command: ICommand, options: ICommandOptions): Promise<{ newPlan: IVistoPlan; undoUnit?: any }> => {

    trackClient.event(command.name);

    const action = await command.prepare();

    return {
      newPlan: await executeAction(action.do, command.message, options, command.details),
      undoUnit: {
        redo: () => executeAction(action.do, command.message && TextService.format(strings.Command_Redo, { message: command.message }), options),
        undo: () => executeAction(action.undo, command.message && TextService.format(strings.Command_Undo, { message: command.message }), options),
      }
    };
  };

  const dispatchCommand = async (command: ICommand, options: ICommandOptions): Promise<IVistoPlan> => {

    while (commandPendingRef.current) {
      await new Promise(r => setTimeout(r, 250));
    }

    if (!editorUiRef.current?.editor) {
      // trackClient.debug(`executing command without undo`);
      const { newPlan } = await doDispatchCommand(command, options);
      return newPlan;
    }

    const graph: mxgraph.mxGraph = editorUiRef.current.editor.graph;
    const model: mxgraph.mxGraphModel = graph.model;

    if (model.currentEdit && model.currentEdit.changes.length) {
      const changes = model.currentEdit.changes;
      // trackClient.debug(`using existing undo scope`);
      const { newPlan, undoUnit } = await doDispatchCommand(command, options);
      changes.push(undoUnit);
      if (options.repaintCellIds) {
        updateDiagramFromPlan(graph, newPlan, options.repaintCellIds);
      }
      return newPlan;
    } else {
      // trackClient.debug(`starting new undo scope`);
      model.beginUpdate();
      const { newPlan, undoUnit } = await doDispatchCommand(command, options);
      model.currentEdit.changes.push(undoUnit);
      updateDiagramFromPlan(graph, newPlan, options.repaintCellIds);
      model.endUpdate();
      editorUiRef.current.editor.setModified(false);
      return newPlan;
    }
  };

  const ensureUiLanguage = (language: string) => {
    const planSettings = planRef.current && PlanSettingsService.getPlanSettings(planRef.current);
    if (language !== TextService.uiLanguage || JSON.stringify(planSettings.customStrings) !== JSON.stringify(TextService.customStrings)) {
      TextService.setUiLanguage(language, planSettings.customStrings);
      forceUpdate();
    }
  };

  const setCurrentPlan = (p: IVistoPlan) => {
    planRef.current = p;
    if (appContextRef.current) {

      const activeFocus = PlanDataService.getActiveFocus(p);
      if (activeFocus) {
        const changes: Partial<IPropertyBag> = {};
        if (activeFocus.startDate) {
          changes.viewStartDate = activeFocus.startDate;
        }
        if (activeFocus.endDate) {
          changes.viewEndDate = activeFocus.endDate;
        }
        appContextRef.current.setPropertyBag(changes);
      }

      const planSettings = PlanSettingsService.getPlanSettings(p);
      ensureUiLanguage(planSettings.language);
    }
  };

  const getPlan = async (): Promise<IVistoPlan> => {
    setIsLoading(TextService.format(strings.AuthContext_LoadingPlan));
    if (isPlanLive) {
      const planInfo = { siteUrl: props.source.siteUrl, planId: props.source.planId };
      const result = await StorageService.get(props.source.siteUrl).loadPlanItem(planInfo);

      if (PlanDataService.isPlanOutdated(result.planVersion) && UrlService.isLocalUrl(result.siteUrl)) {
        return PlanDataService.upgradeFilePlan(result);
      }

      return result;
    } else {
      const blob = await StorageService.get(props.source.planId).getFile(props.source.planId);
      const text = await blob.text();
      const result = parseJSON(text);

      if (PlanDataService.isPlanOutdated(result.planVersion)) {
        return PlanDataService.upgradeFilePlan(result);
      }
      return result;
    }
  };

  const [tutorialStep, _setTutorialSetp] = React.useState(localStorage.getItem('visplan_tutorial_step'));
  const setTutorialSetp = (val: string) => {
    _setTutorialSetp(val);
    localStorage.setItem('visplan_tutorial_step', val);
  }

  const configureLicense = (license: ILicenseInfo) => {

    LicenseService.setLicense(license);

    // update property bag license trim
    appContextRef.current.setPropertyBag({});

    if (license.isLocked) {
      appContextRef.current.isPlanEditEnabled = false;
    }

    if (isPlanLocal) {
      if (!isPlanDesktop) {
        notifications.addNotification({
          type: NotificationType.warn,
          message: license.infoText
        });
      }
    } else {

      if (license.notificationText && !license.isLocked) {
        notifications.addNotification({
          type: NotificationType.warn,
          message: license.notificationText,
          bubble: license.bubbleNotificationText,
          group: 'bubble'
        });
      }

      if (license.isLocked) {
        notifications.addNotification({
          type: NotificationType.warn,
          message: license.notificationText || license.infoText,
          bubble: license.bubbleNotificationText || TextService.format(strings.AuthContext_LicenseError),
          group: 'bubble'
        });
      }
    }
  };

  const connectToPlan = async (newPlan: IVistoPlan) => {
    try {
      const result = await ApiService.openPlan(envContext, newPlan.planId);
      const license = result.license;
      const rtContext = props.getRealtimeService(envContext.userObjectId, result.container_id, realtimeUpdate);
      notifications.clearNotifications('wsconnect')
      if (rtContext) {
        appContextRef.current.wsContext = rtContext;
        rtContext.connect(!reopeningPlan).catch(err => {
          notifications.addNotification({
            type: NotificationType.warn,
            group: 'wsconnect',
            message: 'Unable to open realtime communications channel for this plan.',
            error: err
          });
        });
      }
      return license;
    } catch (err) {
      trackClient.error(`Unable to get license for plan ${newPlan.planId}`, err);
    }
  }

  const initFromPlan = async (newPlan: IVistoPlan) => {

    setCurrentPlan(newPlan);

    const isOutdated = PlanDataService.isPlanOutdated(newPlan.planVersion);
    setOutdated(isOutdated);

    if (isOutdated) {
      setIsLoading('');
      trackClient.warn(`The plan with ${newPlan.planId} is outdated and needs to be upgraded.`);
      return;
    }

    const propertyBag = PropertyBagService.loadSettings();

    if (!isPlanLive || isPlanDesktop) {
      propertyBag.showAdvisory = false;
      propertyBag.skipGettingStarted = true;
    }

    appContextRef.current = {

      propertyBag: propertyBag,
      setPropertyBag: (val: Partial<IPropertyBag>) => {
        const oldPropertyBag = appContextRef.current.propertyBag;
        const newPropertyBag = { ...oldPropertyBag, ...val };
        appContextRef.current.propertyBag = PropertyBagService.saveSettings(newPropertyBag);
        forceUpdate();
      },

      dispatchCommand: dispatchCommand,

      planId: newPlan.planId,

      wsContext: null,
      notify: {
        operation: (op: api.WSOperation, kind: VistoKind, guid: string) => {
          if (appContextRef.current.wsContext) {
            return appContextRef.current.wsContext.notifyItemOperation(op, newPlan.planId, kind, guid);
          }
        },
        plan: (p: IVistoPlan) => {
          setCurrentPlan(p);
          forceUpdate();
        },
        addNotification: (info: INotification) => {
          notifications.addNotification(info);
        },
        clearNotifications: (group?: string) => {
          notifications.clearNotifications(group);
        },
        navigateTo: (target: string) => {
          envContext.subEntityId = target;
          setSelectedTab('plan');
        }
      },

      isPlanEditEnabled: newPlan.editable && isPlanLive,
      isPlanLive: isPlanLive,
      isPlanLocal: isPlanLocal,
      isPlanDesktop: isPlanDesktop,

      isPopupOpen: false,
      setIsPopupOpen: (val: boolean) => {
        appContextRef.current.isPopupOpen = val;
        forceUpdate();
      },

      setDrawingData: (xml: string, force: boolean) => {
        if (force)
          persistDrawingData(xml);
        else
          setDrawingDataToSave(xml);
      },

      planRef,

      editorUiRef,
      setEditorUi: (editorUi: EditorUi) => {
        editorUiRef.current = editorUi;
        if (planRef.current == newPlan) {
          if (editorUi) {
            loadPlanData(editorUi, newPlan);
          }
        } else {
          if (editorUi?.editor?.graph) {
            updateDiagramFromPlan(editorUi.editor.graph, planRef.current);
          }
          forceUpdate();
        }
        if (editorUi?.editor?.undoManager)
          editorUi.editor.undoManager.clear();
      },
    };

    UserInfoService.configure(envContext.tid, appContextRef.current.notify);

    const localLicense = LicenseService.getLocalPlanLicense(newPlan);
    if (localLicense) {
      configureLicense(localLicense);
    } else {
      const cachedLicense = LicenseService.getCachedLicense(newPlan);
      if (cachedLicense) {
        configureLicense(cachedLicense);
      }
      connectToPlan(newPlan).then(onlineLicense => {
        LicenseService.setCachedLicense(newPlan, onlineLicense);
        configureLicense(onlineLicense);
      });
    }

    const planSettings = PlanSettingsService.getPlanSettings(newPlan);
    if (planSettings.memberEditLevel === 'view' && AuthService.getUserTeamRole() === UserTeamRole.User) {
      appContextRef.current.isPlanEditEnabled = false;
    }
    if (planSettings.guestEditLevel === 'view' && AuthService.getUserTeamRole() === UserTeamRole.Guest) {
      appContextRef.current.isPlanEditEnabled = false;
    }

    if (isPlanLive) {
      if (!propertyBag.skipGettingStarted) {
        setShowGettingStarted({ enabled: true, checkbox: true });
      } else {
        if (!tutorialStep) {
          setTutorialSetp('first');
        }
      }
    }

    forceUpdate();
  };

  const init = async () => {
    try {
      const result = await getPlan();
      await initFromPlan(result);
    } catch (err) {
      setIsLoading('');
      StorageCacheService.resetCache();
      if (isConsentError(err)) {
        setConsentError({ type: NotificationType.warn, message: TextService.format(strings.AuthService_ErrorGetConsent, { reason: stringifyError(err) }) });
      } else {
        setMissing(TextService.format(strings.ErrorMessage_UnableToLoadPlan, { planId: props.source.planId, error: stringifyError(err) }));
        trackClient.error(`Unable to load plan ${props.source.planId} from ${props.source.siteUrl}.`);
      }
    }
  }

  const onUpgradeFinished = () => {
    setOutdated(false);
    init();
  };

  React.useEffect(() => {

    init();

    const onBeforeUnload = (evt: BeforeUnloadEvent) => {
      return commandPendingRef.current || savePendingRef.current || editorUiRef.current?.editor?.modified;
    };

    AuthService.notifyThrottling = (wait) => {
      notifications.addNotification({
        type: NotificationType.warn,
        message: TextService.format(strings.Warning_ServerOverloaded, { wait })
      });
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      appContextRef.current?.wsContext?.disconnect();

      planReadOnlyRef.current = reopeningPlan;
      appContextRef.current = null;
      planRef.current = null;
      editorUiRef.current = null;
      notifications.clearNotifications();
      savePendingRef.current = false;
      commandPendingRef.current = false;
      afterSaveActionsRef.current = [];
      afterCommandActionsRef.current = [];

      window.removeEventListener('beforeunload', onBeforeUnload);
      setRealtimeStatus(null);
    };


  }, [props.source]);

  const [realtimeStatus, setRealtimeStatus] = React.useState<api.IWSPlanStatus>(null);

  const [isPlanReadOnlyPending, setIsPlanReadOnlyPending] = React.useState(false);

  const isPlanGraphicalEditDisabledByRole = (p: IVistoPlan) => {
    const planSettings = PlanSettingsService.getPlanSettings(p);
    if (planSettings.memberEditLevel !== 'full' && AuthService.getUserTeamRole() === UserTeamRole.User) {
      return true;
    }
    if (planSettings.guestEditLevel !== 'full' && AuthService.getUserTeamRole() === UserTeamRole.Guest) {
      return true;
    }
    return false;
  }

  const updatePlanThumbnail = async () => {
    const plan = planRef.current;
    const b = await DashboardDataService.getPlanCardPreview(plan.drawingXml, plan.styleJson, theme);
    await DashboardDataService.updatePlanPreview(planRef.current, b);
  };

  const waitPlanSaved = () => {
    if (commandPendingRef.current || savePendingRef.current) {
      setTimeout(waitPlanSaved, 200);
    } else {
      const plan = planRef.current;
      IntegrationService.updatePlanChildRefs(plan);

      // update thumbnail
      updatePlanThumbnail().finally(() => {
        planReadOnlyRef.current = true;
        setIsPlanReadOnlyPending(false);
      });
    }
  };

  const setPlanReadOnly = async (val: boolean) => {

    try {
      if (val) {
        const graph = editorUiRef.current?.editor?.graph;
        if (graph) {
          graph.stopEditing(false);
          mx.removeSizingRect(graph);
          setIsPlanReadOnlyPending(true);
          setTimeout(waitPlanSaved, 200);
        }
      } else {
        setIsPlanReadOnlyPending(true);
        trackClient.page('OpenEditMode');
        const ss = StorageService.get(planRef.current.siteUrl);
        ss.resetListCache(planRef.current);
        const { drawingXml } = await ss.loadPlanItem(planRef.current, ['drawingXml']);
        planRef.current.drawingXml = drawingXml;
        mx.setGraphXml(editorUiRef.current.editor, drawingXml);
        clearHighlights();
        updateHighlights(editorUiRef.current.editor.graph, realtimeStatus);
        planReadOnlyRef.current = false;
        updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
        setIsPlanReadOnlyPending(false);
        resetAssistant(planRef.current)
      }
    } catch (error) {
      console.error(error);
      notifications.addNotification({ type: NotificationType.warn, message: TextService.format(strings.MainFrame_UnableToSwitchEdit), error });
      setIsPlanReadOnlyPending(false);
    }
  };

  const realtimeStatusUpdate = (newStatus: api.IWSPlanStatus) => {

    // plan is newer on the server
    if (planRef.current && newStatus.drawing_revision > planRef.current.drawingRevision) {
      if (planReadOnlyRef.current && planRef.current.drawingRevision) {
        const ss = StorageService.get(planRef.current.siteUrl);
        ss.resetListCache(planRef.current);
        ss.loadPlanItem(planRef.current, ['drawingXml']).then(loaded => {
          const editor = editorUiRef.current?.editor;
          const graph: mxgraph.mxGraph = editor?.graph;
          const sel = mx.getSelection(graph);
          planRef.current.drawingXml = loaded.drawingXml;
          mx.setGraphXml(editor, loaded.drawingXml);
          clearHighlights();
          updateHighlights(graph, newStatus);
          updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
          if (sel) {
            mx.setSelection(graph, sel);
          }
          editor.setModified(false);
        });
      }
      planRef.current.drawingRevision = newStatus.drawing_revision;
    }

    if (editorUiRef.current?.editor?.graph) {
      updateHighlights(editorUiRef.current?.editor?.graph, newStatus);
    }

    setRealtimeStatus(newStatus);
  };

  const realtimeItemUpdate = (op: api.IWSItemOperationInfo) => {

    const ss = StorageService.get(planRef.current.siteUrl);
    switch (op.operation) {
      case api.WSOperation.create:
      case api.WSOperation.update:
        ss.resetListCache(planRef.current, op.kind);
        ss.loadItem(planRef.current, op.kind, op.guid, appContextRef.current.notify).then(item => {
          setCurrentPlan(PlanDataService.mergeItem(planRef.current, item));
          updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
          forceUpdate();
        }, err => {
          trackClient.error(`Failed to load the item item ${op.guid} in realtime`, err);
        });
        break;
      case api.WSOperation.delete:
        setCurrentPlan(PlanDataService.dropItem(planRef.current, op.kind, op.guid));
        updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
        forceUpdate();
        break;
      case api.WSOperation.settings:
        ss.resetListCache(planRef.current);
        ss.loadPlanItem(planRef.current, ['settingsJson', 'name', 'description', 'esName', 'esDescription']).then(loaded => {
          Object.assign(planRef.current, {
            settingsJson: loaded.settingsJson,
            name: loaded.name,
            description: loaded.description,
            esName: loaded.esName,
            esDescription: loaded.esDescription
          });
          const newPlanSettings = PlanSettingsService.getPlanSettings(loaded);
          ensureUiLanguage(newPlanSettings.language);
          updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
        });
        break;
    }
  };

  const realtimeUpdate = (msg: api.IWSMessage) => {
    switch (msg.type) {
      case api.WSMessageType.disconnected:
        realtimeStatusUpdate({ ...realtimeStatus });
        break;

      case api.WSMessageType.status:
        realtimeStatusUpdate(msg as api.IWSPlanStatus);
        break;

      case api.WSMessageType.item:
        if (commandPendingRef.current) {
          afterCommandActionsRef.current.push(() => realtimeItemUpdate(msg as api.IWSItemOperationInfo));
        } else if (savePendingRef.current) {
          afterSaveActionsRef.current.push(() => realtimeItemUpdate(msg as api.IWSItemOperationInfo));
        } else {
          if (planRef.current) {
            realtimeItemUpdate(msg as api.IWSItemOperationInfo);
          }
        }
        break;
    }
  };

  const [showGettingStarted, setShowGettingStarted] = React.useState({ enabled: false, checkbox: true });
  const onDismissGettingStarted = (val: boolean) => {
    setShowGettingStarted({ enabled: false, checkbox: true });
    if (!tutorialStep) {
      setTutorialSetp('first');
    }
    appContextRef.current.setPropertyBag({ skipGettingStarted: val });
  };

  const [showLicenseDialog, setShowLicenseDialog] = React.useState(false);
  const [showPlanViewSettingsDialog, setShowPlanViewSettingsDialog] = React.useState(false);
  const [showPlannerWizard, setShowPlannerWizard] = React.useState(false);
  const [showProjectWizard, setShowProjectWizard] = React.useState(false);
  const [showDevOpsWizard, setShowDevOpsWizard] = React.useState(false);

  const [showExportJsonDialog, setShowExportJsonDialog] = React.useState(false);
  const [showExportTemplateDialog, setShowExportTemplateDialog] = React.useState(false);
  const [showExportPngDialog, setShowExportPngDialog] = React.useState(false);
  const [showExportSvgDialog, setShowExportSvgDialog] = React.useState(false);

  const menuItems: IContextualMenuItem[] = [
    {
      key: 'PlanViewSettings',
      text: TextService.format(strings.MainFrame_Menu_PlanViewSettings),
      disabled: !isPlanLive,
      onClick: () => setShowPlanViewSettingsDialog(true)
    },
    {
      key: 'ExportPlan',
      text: TextService.format(strings.PlanSettings_ButtonExportPlan),
      subMenuProps: {
        items: [
          ...(LicenseService.license?.templatesEnabled ? [{
            key: 'ExportPlanTemplate',
            text: TextService.format(strings.MainFrame_Menu_ExportTemplate),
            onClick: () => setShowExportTemplateDialog(true),
          }] : []),
          {
            key: 'ExportPlanJSON',
            text: TextService.format(strings.MainFrame_Menu_ExportPlanData),
            onClick: () => setShowExportJsonDialog(true),
          },
          MenuItems.getDividerMenuItem(1),
          {
            key: 'ExportPlanSVG',
            text: TextService.format(strings.MainFrame_Menu_ExportPlanSVG),
            onClick: () => setShowExportSvgDialog(true),
          },
          {
            key: 'ExportPlanPNG',
            text: TextService.format(strings.MainFrame_Menu_ExportPlanPNG),
            onClick: () => setShowExportPngDialog(true),
          },
        ]
      }
    },
    // for now printing is blocked in all client types except web browser
    ...((envContext.hostKind === 'TeamsWeb' || envContext.hostKind === 'WebDesktop') ? [{
      key: 'PrintPlan',
      text: TextService.format(strings.MainFrame_Menu_Print),
      onClick: () => PrintService.printPlan(planRef.current, editorUiRef.current.editor.graph),
      disabled: !editorUiRef.current?.editor?.graph
    }] : []),
    MenuItems.getDividerMenuItem(11),
    {
      key: 'MicrosoftPlanner',
      text: TextService.format(strings.MainFrame_Menu_Planner),
      subMenuProps: {
        items: [
          {
            key: 'MicrosoftPlanner_NewPlan',
            text: TextService.format(strings.MainFrame_Menu_Planner_Wizard),
            disabled: !(LicenseService?.license?.plannerEnabled && appContextRef.current?.isPlanEditEnabled),
            onClick: () => setShowPlannerWizard(true)
          },
          {
            key: 'MicrosoftPlanner_Link',
            text: TextService.format(strings.MainFrame_Menu_Planner_Open),
            disabled: !(LicenseService?.license?.plannerEnabled),
            href: 'https://tasks.office.com',
            target: `visto_planner_${planRef.current?.planId}`
          },
        ]
      }
    },
    {
      key: 'MicrosoftDevOps',
      text: TextService.format(strings.MainFrame_Menu_DevOps),
      subMenuProps: {
        items: [
          {
            key: 'MicrosoftDevOps_Configure',
            text: TextService.format(strings.MainFrame_Menu_DevOps_Wizard),
            disabled: !(LicenseService?.license?.devopsEnabled && appContextRef.current?.isPlanEditEnabled),
            onClick: () => setShowDevOpsWizard(true)
          },
          {
            key: 'MicrosoftDevOps_Link',
            text: TextService.format(strings.MainFrame_Menu_DevOps_Open),
            disabled: !(LicenseService?.license?.devopsEnabled && DevOpsService.getPlanDevOpsUrl(planRef.current)),
            href: DevOpsService.getPlanDevOpsUrl(planRef.current),
            target: `visto_devops_${planRef.current?.planId}`
          },
        ]
      }
    },
    {
      key: 'MicrosoftProject',
      text: TextService.format(strings.MainFrame_Menu_Project),
      subMenuProps: {
        items: [
          {
            key: 'MicrosoftProject_Configure',
            text: TextService.format(strings.MainFrame_Menu_Project_Wizard),
            disabled: !(LicenseService?.license?.projectEnabled && appContextRef.current?.isPlanEditEnabled),
            onClick: () => setShowProjectWizard(true)
          },
          {
            key: 'MicrosoftProject_Link',
            text: TextService.format(strings.MainFrame_Menu_Project_Open),
            disabled: !(LicenseService?.license?.projectEnabled && PlanSettingsService.getPlanSettings(planRef.current)?.integrations?.project?.pwaUrl),
            href: PlanSettingsService.getPlanSettings(planRef.current)?.integrations?.project?.pwaUrl,
            target: `visto_project_${planRef.current?.planId}`
          },
        ]
      }
    },
    MenuItems.getDividerMenuItem(21),
    {
      key: 'Tutorial',
      text: TextService.format(strings.MainFrame_Menu_Help),
      onClick: () => setShowGettingStarted({ enabled: true, checkbox: false })
    },
    {
      key: 'Support',
      text: TextService.format(strings.MainFrame_Menu_Support),
      href: 'https://visplan.com/support',
      target: '_blank'
    },
    {
      key: 'License',
      text: TextService.format(strings.MainFrame_Menu_License),
      onClick: () => setShowLicenseDialog(true)
    },
  ];

  const onWizardFinished = (changed: boolean, plan: IVistoPlan) => {
    if (changed) {
      setCurrentPlan(plan);
      updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
      forceUpdate();
    }
  };

  const onProjectWizardDismiss = (changed: boolean, plan: IVistoPlan) => {
    setShowProjectWizard(false);
    onWizardFinished(changed, plan);
  }

  const onDevOpsWizardDismiss = (changed: boolean, plan: IVistoPlan) => {
    setShowDevOpsWizard(false);
    onWizardFinished(changed, plan);
  }

  const onPlannerWizardDismiss = (changed: boolean, plan: IVistoPlan) => {
    setShowPlannerWizard(false);
    onWizardFinished(changed, plan);
    if (changed) {
      navigate('https://tasks.office.com', `visto_planner_${planRef.current?.planId}`);
    }
  };

  const onRefreshClick = () => {
    dispatchCommand(RefreshCommand.makeRefreshCommand(appContextRef.current.notify), { wrap: true });
  };

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [consentError, setConsentError] = useErrorInfo();

  const consent = async () => {
    try {
      setIsProcessing(true);
      const newPlan = await AuthService.getConsent(api.TokenKind.sharepoint, UrlService.getDomain(props.source.siteUrl), getPlan);
      setConsentError(null);
      initFromPlan(newPlan);
    } catch (error) {
      setConsentError({ type: NotificationType.warn, message: TextService.format(strings.AuthService_ErrorGetConsent, { reason: stringifyError(error) }) });
    } finally {
      setIsLoading('');
      setIsProcessing(false);
    }
  };

  const [fontSize, setFontSize] = React.useState(14);

  const zoomInfoKey = `PlanView_Zoom_${planRef.current?.planId}`;

  const [visualFilter, _setVisualFilter] = React.useState<IVisualFilter>({
    assignees: [],
    lopGuids: [],
    focusGuids: [],
    krGuids: [],
  });
  const setVisualFilter = (filter: IVisualFilter) => {
    _setVisualFilter(filter);
    VisualFilterService.saveFilter(planRef.current, filter);
  }

  const onVisualFilterChange = (filter: IVisualFilter) => {
    VisualFilterService.saveFilter(planRef.current, filter);
    updateDiagramFromPlan(editorUiRef.current.editor.graph, planRef.current);
  }

  const [isAssistantSidebarVisible, _setIsAssistantSidebarVisible] = React.useState(localStorage.getItem('visplan_assistant') !== 'false');
  const setIsAssistantSidebarVisible = (val: boolean) => {
    _setIsAssistantSidebarVisible(val);
    localStorage.setItem('visplan_assistant', val ? 'true' : 'false');
  }

  const [assistantCards, setAssistantCards] = React.useState<IAssistatCard[]>([]);

  const [isGenerating, setIsGenerating] = React.useState(false);

  const onGenerateClick = async (text: string) => {

    const cardsBeforeRequest = [...assistantCards];

    const cardsWithUserRequest: IAssistatCard[] = [
      ...assistantCards,
      { role: 'user', content: text }
    ];

    const formatChanges = (changes: IPlanGenerationUpdates): JSX.Element => {
      return <>
        {changes.addedCapabilities.length > 0 &&
          <Stack>
            <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_AddedLOP)}</Text>
            <ul>
              {changes.addedCapabilities.map(capability => <li key={capability.guid}><strong>{capability.name}</strong></li>)}
            </ul>
          </Stack>}
        {changes.addedAmbitions.length > 0 &&
          <Stack>
            <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_AddedDP)}</Text>
            <ul>
              {changes.addedAmbitions.map(ambition => <li key={ambition.guid}><strong>{ambition.name}</strong></li>)}
            </ul>
          </Stack>}
        {changes.deletedCapabilities.length > 0 &&
          <Stack>
            <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_DeletedLOP)}</Text>
            <ul>
              {changes.deletedCapabilities.map(capability => <li key={capability.guid}><strong>{capability.name}</strong></li>)}
            </ul>
          </Stack>}
        {changes.deletedAmbitions.length > 0 &&
          <Stack>
            <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_DeletedDP)}</Text>
            <ul>
              {changes.deletedAmbitions.map(ambition => <li key={ambition.guid}><strong>{ambition.name}</strong></li>)}
            </ul>
          </Stack>}
        {changes.changedCapabilities.length > 0 &&
          <Stack>
            <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_ChangedLOP)}</Text>
            <ul>
              {changes.changedCapabilities.map(capability => <li key={capability.item.guid}>
                <strong>{capability.item.name}</strong>
                <div dangerouslySetInnerHTML={{ __html: TextService.formatChanges(VistoKind.LOP, capability.changes) }}></div>
                </li>)}
            </ul>
          </Stack>}
        {changes.changedAmbitions.length > 0 &&
          <Stack>
            <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_ChangedDP)}</Text>
            <ul>
              {changes.changedAmbitions.map(ambition => <li key={ambition.item.guid}>
                <strong>{ambition.item.name}</strong>
                <div dangerouslySetInnerHTML={{ __html: TextService.formatChanges(VistoKind.DP, ambition.changes) }}></div>
              </li>)}
            </ul>
          </Stack>}
      </>
    }

    try {

      setIsGenerating(true);

      setAssistantCards([
        ...cardsWithUserRequest,
        { role: 'progress', content:  TextService.format(strings.Assistant_Thinking) }
      ]);

      const messages = cardsWithUserRequest
        .filter(c => c.role === 'assistant' || c.role === 'user' || c.role === 'system')
        .map(c => ({ role: c.role as 'user' | 'assistant' | 'system', content: c.content }));

      const oldPlan = { ...planRef.current };
      const assistantJson = await PlanGenerationService.getAssistantPlan(messages);
      const { newPlan, changes } = PlanGenerationService.generateVistoPlan(assistantJson, oldPlan);

      if (planReadOnlyRef.current) {
        if (changes.addedAmbitions.length || changes.addedCapabilities.length || changes.deletedAmbitions.length || changes.deletedCapabilities.length) {
          throw new Error(TextService.format(strings.Assistant_ErrorGraphicsChanges));
        }
      }

      const editor = editorUiRef.current.editor;
      const sel = mx.getSelection(editor.graph);

      mx.setGraphXml(editor, newPlan.drawingXml);
      clearHighlights();
      updateHighlights(editor.graph, realtimeStatus);
      updateDiagramFromPlan(editor.graph, newPlan);
      setCurrentPlan(newPlan);

      const acceptButtonLabel = TextService.format(strings.AssistantCardButton_Accept, { count: changes.commands.length });
      const acceptButtonDescription = formatChanges(changes);

      const cardsWithAssistantResponse: IAssistatCard[] = [
        ...cardsWithUserRequest,
        {
          role: 'assistant',
          content: JSON.stringify(assistantJson, null, 2),
          debugPlan: UrlService.isDev ? { ...newPlan, previewPng: undefined } : undefined,
          showButtons: true,
          onReject: async () => {
            try {
              setAssistantCards([...cardsBeforeRequest]);
              setCurrentPlan(oldPlan);
              mx.setGraphXml(editor, oldPlan.drawingXml);
              clearHighlights();
              updateHighlights(editor.graph, realtimeStatus);
              updateDiagramFromPlan(editor.graph, oldPlan);
            } catch (error) {
              setAssistantCards([
                ...cardsWithUserRequest,
                { role: 'error', content: stringifyError(error) }
              ]);
            } finally {
              setIsGenerating(false);
            }
          },
          acceptButtonLabel,
          acceptButtonDescription,
          onAccept: async () => {
            try {
              oldPlan.drawingXml = newPlan.drawingXml;
              setCurrentPlan(oldPlan);
              setAssistantCards([...cardsWithAssistantResponse]);
              for (const command of changes.commands) {
                await dispatchCommand(command, { wrap: true });
              }
              persistDrawingData(newPlan.drawingXml);
            } catch (error) {
              setAssistantCards([
                ...cardsWithUserRequest,
                { role: 'error', content: stringifyError(error) }
              ]);
            } finally {
              setIsGenerating(false);
            }
          }
        },
      ];

      setAssistantCards(cardsWithAssistantResponse);

      if (sel) {
        mx.setSelection(editor.graph, sel);
      }

    } catch (error) {
      setIsGenerating(false);
      setAssistantCards([
        ...cardsWithUserRequest,
        { role: 'error', content: stringifyError(error) }
      ]);
    }
  }

  return consentError
    ? <Stack grow style={{ maxHeight: 200 }}>
      <Placeholder
        iconName='LaptopSecure'
        iconText={TextService.format(strings.AuthContext_ConsentRequired)}
        description={TextService.format(strings.AuthContext_ConsentRequiredExplanation)}
        buttonLabel={TextService.format(strings.AuthContext_ConsentRequiredAction)}
        disableButton={isProcessing}
        onConfigure={consent}
      />
    </Stack>
    : missing
      ? <Stack grow style={{ maxHeight: 200 }}>
        <Placeholder
          iconName='Repair'
          iconText={TextService.format(strings.MainFrame_ErrorMissingPlanTitle)}
          description={missing}
        />
      </Stack>
      : outdated
        ? <UpgradePlanPlaceholder
          siteUrl={planRef.current.siteUrl}
          planId={planRef.current.planId}
          onUpgradeFinished={onUpgradeFinished}
          planName={planRef.current.name}
        />
        : appContextRef.current &&
        <AppContext.Provider value={appContextRef.current}>
          <Stack grow style={{ position: 'relative' }}>
            <NotificationBar notifications={notifications} top={planReadOnlyRef.current ? 45 : 84} />

            <Stack horizontal wrap verticalAlign='center' tokens={{ padding: '0 s1' }}>
              <IconButton styles={{ menuIcon: { fontSize: FontSizes.large } }} menuIconProps={{ iconName: 'GlobalNavButton' }} menuProps={{ items: menuItems }} />
              <Stack.Item grow>
                <Pivot selectedKey={selectedTab} onLinkClick={(item) => setSelectedTab(item.props.itemKey!)} headersOnly={true} getTabId={key => key}>
                  <PivotItem headerText={TextService.format(strings.MainTab_Plan)} itemKey='plan' />
                  {LicenseService.license?.matrixEnabled && <PivotItem headerText={TextService.format(strings.MainTab_Matrix)} itemKey='matrix' />}
                  <PivotItem headerText={TextService.format(strings.MainTab_Gantt)} itemKey='gantt' />
                  {LicenseService.license?.okrEnabled && <PivotItem headerText={TextService.format(strings.MainTab_OKR)} itemKey='okr' />}
                  <PivotItem headerText={TextService.format(strings.MainTab_List)} itemKey='actions' />
                </Pivot>
              </Stack.Item>

              {selectedTab === 'plan' && <IconButton styles={{ icon: { fontSize: FontSizes.large } }} iconProps={{ iconName: 'ChatBot' }} onClick={() => setIsAssistantSidebarVisible(true)} />}

              {selectedTab === 'plan' && <PlanVisualFilter
                plan={planRef.current}
                filter={visualFilter}
                disabled={!planReadOnlyRef.current}
                setFilter={onVisualFilterChange} />
              }

              {isPlanLive && <RefreshButton disabled={false} onClick={onRefreshClick} />}

              <PersonaBar status={realtimeStatus} />

              {selectedTab === 'plan' &&
                <StatusBarZoom zoomInfoKey={zoomInfoKey} editorUi={editorUiRef.current} viewAlignmentX={ViewAlignment.Middle} viewAlignmentY={ViewAlignment.Middle} />
              }
              {selectedTab === 'matrix' &&
                <StatusBarMatrixZoom fontSize={fontSize} setFontSize={setFontSize} />
              }

              <NavigationFrameButton plan={planRef.current} />

              {selectedTab === 'plan' &&
                <ToggleEditButton disabled={isPlanReadOnlyPending || isPlanGraphicalEditDisabledByRole(planRef.current)} status={realtimeStatus} readOnly={planReadOnlyRef.current} setReadOnly={setPlanReadOnly} />
              }
              {selectedTab === 'matrix' &&
                <ToggleEditButton disabled status={realtimeStatus} readOnly={matrixReadOnly} setReadOnly={setMatrixReadOnly} />
              }
              {selectedTab === 'gantt' &&
                <ToggleEditButton status={realtimeStatus} readOnly={ganttReadOnly} setReadOnly={setGanttReadOnly} />
              }
              {selectedTab === 'actions' &&
                <ToggleEditButton status={realtimeStatus} readOnly={actionsReadOnly} setReadOnly={setActionsReadOnly} />
              }
              {/* {selectedTab === 'okr' &&
                <ToggleEditButton status={realtimeStatus} readOnly={okrReadOnly} setReadOnly={setOkrReadOnly} />
              } */}
            </Stack>

            <GenerateSidebar
              isOpen={isAssistantSidebarVisible}
              isGenerating={isGenerating}
              cards={assistantCards}
              onClearMessages={() => resetAssistant(planRef.current)}
              onDismiss={() => setIsAssistantSidebarVisible(false)}
              onGenerate={onGenerateClick}
            />

            {selectedTab == 'plan' &&
              <TopFrame readOnly={planReadOnlyRef.current} setReadOnly={setPlanReadOnly} />
            }
            {selectedTab == 'matrix' &&
              <MatrixFrame fontSize={fontSize} />
            }
            {selectedTab == 'gantt' &&
              <GanttFrame readOnly={ganttReadOnly} />
            }
            {selectedTab == 'actions' &&
              <ActionListFrame readOnly={actionsReadOnly} />
            }
            {selectedTab == 'okr' && LicenseService.license?.okrEnabled &&
              <OkrFrame readOnly={okrReadOnly} notifications={appContextRef.current.notify} />
            }
          </Stack>


          {showGettingStarted.enabled && <GettingStartedDialog onDismiss={(val) => onDismissGettingStarted(val)} topic='index'
            showCheckbox={showGettingStarted.checkbox} skipGettingStarted={appContextRef.current?.propertyBag?.skipGettingStarted} />}
          {showLicenseDialog && <LicenseDialog plan={planRef.current} onDismiss={() => setShowLicenseDialog(false)} />}
          {showPlanViewSettingsDialog && <PlanViewSettingsDialog plan={planRef.current} onDismiss={() => setShowPlanViewSettingsDialog(false)} />}
          {showPlannerWizard && <PlannerPlanWizard plan={planRef.current} onDismiss={onPlannerWizardDismiss} />}
          {showProjectWizard && <ProjectWizard plan={planRef.current} onDismiss={onProjectWizardDismiss} />}
          {showDevOpsWizard && <DevOpsWizard plan={planRef.current} onDismiss={onDevOpsWizardDismiss} />}
          {showExportJsonDialog && <ExportPlanJsonDialog plan={planRef.current} editorUi={editorUiRef.current} onDismiss={() => setShowExportJsonDialog(null)} />}
          {showExportTemplateDialog && <ExportPlanTemplateDialog plan={planRef.current} editorUi={editorUiRef.current} onDismiss={() => setShowExportTemplateDialog(false)} />}
          {showExportSvgDialog && <ExportPlanSvgDialog plan={planRef.current} editorUi={editorUiRef.current} onDismiss={() => setShowExportSvgDialog(null)} />}
          {showExportPngDialog && <ExportPlanPngDialog plan={planRef.current} editorUi={editorUiRef.current} onDismiss={() => setShowExportPngDialog(null)} />}
          {activeKeyResult && <EditKeyResultValuesDialog plan={planRef.current} kr={activeKeyResult} onDismiss={() => setActiveKeyResult(null)} />}

          {selectedTab === 'plan' && !planReadOnlyRef.current && <Tutorial step={tutorialStep} setStep={setTutorialSetp} />}
        </AppContext.Provider>;
};
