import React from 'react';
import { Dropdown, ResponsiveMode } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';

export const LanguageDropdown = (props: {
  language: string;
  setLanguage: (val: string) => void;
}) => {

  const languages = [
    {
      key: 'en-us',
      text: TextService.format(strings.LanguageDropdown_English)
    },
    {
      key: 'sv-se',
      text: TextService.format(strings.LanguageDropdown_Swedish)
    }
  ];

  return (
    <Dropdown
      style={{ minWidth: 150 }}
      label={TextService.format(strings.LanguageDropdown_Label)}
      options={languages}
      selectedKey={props.language}
      onChange={(_, val) => props.setLanguage('' + val.key)}
      responsiveMode={ResponsiveMode.large}
    />
  );
};
